(function() {

    // Search Form
    let inputSearchDepartement = document.querySelector('input[name="departement"]');
    let inputSearchVille = document.querySelector('input[name="ville"]');
    // Newsletter
    let newsletterForm = document.querySelectorAll('.newsletter-form');
    // Programmes / terrains
    let favoriteButton = document.querySelectorAll('.favori');
    let documentsButton = document.querySelectorAll('.zone-plaquette label');
    let detailsButton = document.querySelectorAll('.lot .voir label');
    let detailsPanel = document.querySelector('.aside-detail-panel');
    if (detailsPanel != null && typeof(detailsPanel) != "undefined") {
        var modelDetailsHtml = detailsPanel.innerHTML;
    }
    let documentsPanel = document.querySelector('.aside-document-panel');
    let documentForm = document.querySelector('.aside-document-panel .wpcf7');
    let planPanel = document.querySelector('.aside-plan-panel');
    let hiddenInputsArr = document.querySelectorAll('aside input[name*="lot-"]');
    let allPanels = document.querySelectorAll('input[name="panels"]');
    // Utilisateurs
    let loginForm = document.getElementById('user_login');
    let registerForm = document.getElementById('user_registration');
    let resetPasswordForm = document.getElementById('password_reset');
    let infosForm = document.getElementById('form-informations');
    let deleteBtn = document.getElementById('delete-account-btn');
    // Contact
    // let contactButton = document.querySelectorAll('label[for="contact-panel"]');
    // let contactPanel = document.querySelector('.aside-contact-panel');
    let radioInput = document.querySelectorAll('.trigger');

    // Ajax sur le submit Newsletter
    if (newsletterForm != null && typeof(newsletterForm) != "undefined") {
        newsletterForm.forEach(function (element, id) {
            element.addEventListener('submit', function(e) {
                e.preventDefault();

                var newsletterInput = element.querySelector('input[type="email"]');
                var newsletterSubmit = element.querySelector('input[type="submit"]');

                newsletterInput.disabled = true;
                newsletterSubmit.disabled= true;

                let request = new XMLHttpRequest();
                request.open('GET', `${FunctionsAjax.ajaxurl}?action=ajax_newsletter_send&email=${newsletterInput.value}&security=${FunctionsAjax.security}`, true);
                request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');

                request.onload = function() {
                    newsletterInput.disabled = false;
                    newsletterSubmit.disabled= false;
                    console.log(this.response);
                    if (200 === this.status) {
                        let rep = JSON.parse(this.response);
                        if(rep.type == "success") {
                            // add message success
                            newsletterInputContainer.innerHTML = '<p><i>Vous êtes bien inscrit à la newsletter !</i></p>';
                        }
                    } else {
                        // add message error
                        console.log('fail | status : ' + this.status + ' | response : ' + this.response);
                    }
                };
                request.onerror = function() {
                    console.log('connection error');
                };
                request.send();
            });
        });
    }

    // Ajax programme favoris
    if (favoriteButton != null && typeof(favoriteButton) != "undefined") {
        favoriteButton.forEach(function (element, id) {
            element.addEventListener('click', function(e) {
                e.preventDefault();
                let idProgramme = element.dataset.id;

                let request = new XMLHttpRequest();
                request.open('GET', `${FunctionsAjax.ajaxurl}?action=favorite_programme&programme_id=${idProgramme}`, true);
                request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
                request.onload = function() {
                    if (200 === this.status) {
                        let rep = JSON.parse(this.response);
                        if(rep.type == "success") {
                            // add message success
                            var icoFavorites = element.dataset.ico;
                            if(icoFavorites == 'liked') {
                                element.setAttribute("data-ico", "favorites");
                            } else if(icoFavorites == 'favorites') {
                                element.setAttribute("data-ico", "liked");
                            }
                            if(FunctionsAjax.is_dashboard) {
                                let toHide = document.getElementById('favorite-program-' + idProgramme);
                                let favoriteNumbers = document.getElementsByClassName('number-favorites');
                                [...favoriteNumbers].forEach(number => {
                                    number.innerHTML--;
                                });
                                toHide.classList.add('delete-favorite');
                                window.setTimeout(() => {
                                    toHide.parentNode.removeChild(toHide);
                                }, 400);
                            }
                        }
                    } else {
                        // add message error
                        console.log('fail | status : ' + this.status + ' | response : ' + this.response);
                    }
                };
                request.onerror = function() {
                    console.log('connection error');
                };
                request.send();
            });
        });
    }

    // NO Ajax Panel document d'un programme
    if (documentForm !== null && typeof(documentForm) !== undefined && documentsButton != null && typeof(documentsButton) != "undefined") {
        documentsButton.forEach(function (element) {
            element.addEventListener('click', function(e) {
                let programDir = element.getAttribute('data-document-link');
                let documentLabel = element.getAttribute('data-trk-document');

                // Add document values in html panel
                var itemDocumentsBannerHtml = documentsPanel.querySelector('.banner-header');
                var modelDocumentsBannerHtml = itemDocumentsBannerHtml.innerHTML;
                var modelDocumentsBannerHtmlTmp = modelDocumentsBannerHtml;

                var itemDocumentsDescriptionHtml = documentsPanel.querySelector('.description-inner-html');
                var modelDocumentsDescriptionHtml = itemDocumentsDescriptionHtml.innerHTML;
                var modelDocumentsDescriptionHtmlTmp = modelDocumentsDescriptionHtml;

                var regexp = new RegExp("%" + "nom" + "%", "g");

                modelDocumentsBannerHtmlTmp = modelDocumentsBannerHtmlTmp.replace(regexp, documentLabel);
                itemDocumentsBannerHtml.innerHTML = modelDocumentsBannerHtmlTmp;
                modelDocumentsDescriptionHtmlTmp = modelDocumentsDescriptionHtmlTmp.replace(regexp, documentLabel);
                itemDocumentsDescriptionHtml.innerHTML = modelDocumentsDescriptionHtmlTmp;

                // Add document url in hidden field Form document
                documentsPanel.querySelector('input[name="your-document"]').value = programDir;


                setTimeout(function(){
                    documentsPanel.classList.remove('notloaded');
                    documentsPanel.classList.add('loaded');
                }, 100);

                let multiHtml = [,
                    itemDocumentsBannerHtml,
                    itemDocumentsDescriptionHtml
                ];
                cleanPanelMultiHtml(documentsPanel, documentLabel, multiHtml);

            });
        })
    }

    // Ajax programme détails + programme simulateur
    if (detailsButton != null && typeof(detailsButton) != "undefined") {
        detailsButton.forEach(function (element, id) {
            // Populer les infos quand on ouvre le détail d'un lot
            element.addEventListener('click', function(e) {
                let referenceLot = element.dataset.lotreference;
                let idProgramme = element.dataset.programmeid;
                let request = new XMLHttpRequest();

                request.open('GET', `${FunctionsAjax.ajaxurl}?action=details_lot&programme_id=${idProgramme}&lot_reference=${referenceLot}`, true);
                request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
                request.onload = function() {
                    if (200 === this.status) {
                        let rep = JSON.parse(this.response);
                        if(rep.type == "success") {
                            var modelDetailsHtml = detailsPanel.innerHTML;
                            var modelDetailsHtmlTmp = modelDetailsHtml;
                            let hasVue, hasPlan;

                            for (const [lotkey, lotvalue] of Object.entries(rep.message)) {
                                var regexp = new RegExp("%" + lotkey + "%", "g");
                                modelDetailsHtmlTmp = modelDetailsHtmlTmp.replace(regexp, lotvalue);
                                //console.log(lotkey + "|" + lotvalue);
                                if (lotkey == "vue_3d" && lotvalue.length > 0) {
                                    hasVue = true;
                                }
                                if (lotkey == "plan" && lotvalue.length > 0) {
                                    hasPlan = true;
                                }
                            }

                            detailsPanel.innerHTML = modelDetailsHtmlTmp;

                            // Get href in direct links from dataset href
                            let vueUrl = document.querySelector('[target="vue"]');
                            let planUrl = document.querySelector('[target="plan"]');

                            if (typeof hasVue !== undefined && vueUrl !== null) {
                                vueUrl.href = vueUrl.dataset.href;
                            }
                            if (typeof hasPlan !== undefined && planUrl !== null) {
                                planUrl.href = planUrl.dataset.href;
                            }

                            // Add vue 3d preview img
                            var vue_preview_img = detailsPanel.querySelector('[data-hasvue] img');
                            if(vue_preview_img.getAttribute('data-src') != 'false' && vue_preview_img.getAttribute('data-src') != '') {
                                vue_preview_img.setAttribute('src', vue_preview_img.getAttribute('data-src'));
                            }

                            // Add plan preview img
                            var plan_preview_img = detailsPanel.querySelector('[data-hasplan] img');
                            if(plan_preview_img.getAttribute('data-src') != 'false' && plan_preview_img.getAttribute('data-src') != '') {
                                plan_preview_img.setAttribute('src', plan_preview_img.getAttribute('data-src'));
                            }

                            // Add plan url in hidden field Form plan
                            planPanel.querySelector('input[name="your-plan"]').value = rep.message.plan;

                            // Add lot-DATAS in hidden field Form contact
                            if (hiddenInputsArr != null && typeof(hiddenInputsArr) != "undefined") {
                                hiddenInputsArr.forEach(function (el, id) {
                                    var elName = el.getAttribute("name");
                                    if (elName != null && typeof(elName) != "undefined") {
                                        if(elName == "lot-reference") {
                                            el.value = "La reference du lot concerne est: " + rep.message.numero;
                                        } else if(elName == "lot-id") {
                                            el.value = rep.message.reference;
                                        } else if(elName == "lot-price") {
                                            el.value = rep.message.prix;
                                        } else if(elName == "lot-floor") {
                                            el.value = rep.message.etage;
                                        } else if(elName == "lot-exposition") {
                                            el.value = rep.message.exposition;
                                        }
                                    }                                    
                                });
                            }

                            setTimeout(function(){
                                detailsPanel.classList.remove('notloaded');
                                detailsPanel.classList.add('loaded');
                            }, 100);

                        }
                    } else {
                        // add message error
                        console.log('fail | status : ' + this.status + ' | response : ' + this.response);
                    }
                };
                request.onerror = function() {
                    console.log('connection error');
                };
                request.send();
                cleanPanel(detailsPanel, modelDetailsHtml, null);
            });
        });


    }

    // Remettre à l'état initial le panel %%
    function cleanPanel(item, model) {
        allPanels.forEach(function (element, id) {
            element.addEventListener('change', function(e) {
                if(e.target.id == "close-all-panels") {
                    if (item != null && typeof(item) != "undefined" && model != null) {
                            setTimeout(function(){
                                item.innerHTML = model;
                                item.classList.remove('loaded');
                                item.classList.add('notloaded');
                                if (hiddenInputsArr != null && typeof(hiddenInputsArr) != "undefined") {
                                    hiddenInputsArr.forEach(function (el, id) {
                                        el.value = "";
                                    });
                                }
                            }, 500);
                        }

                    initCf7();

                }
            });
        });
    }

    // Remettre à l'état initial le panel %% avec multiHTML
    function cleanPanelMultiHtml(panel, label, multiHtml) {
        let closeLabel = document.querySelector('#close-all-panels');
        closeLabel.addEventListener('change', function(e) {
        if (multiHtml !== null) {
                var regexp = new RegExp(label, "g");
                setTimeout(function(){
                    multiHtml.forEach((element, i) => {
                        element.innerHTML = element.innerHTML.replace(regexp, '%nom%');
                    })
                }, 500);
            }

        initCf7();

        });
    }

     // récupérer tous les forms.sent.submitted - remove class
    function initCf7() {
        let cf7 = document.querySelectorAll('.wpcf7-form.submited.sent');
        [...cf7].forEach((form) => {
            form.classList.remove('sent');
            form.classList.remove('submited');
        });

        [...radioInput].forEach((input) => {
            input.checked = false;
        })
    }
    

    // Ajax Register
    if (registerForm != null && typeof(registerForm) != "undefined") {
        registerForm.addEventListener('submit', function(e) {
            e.preventDefault();
            let civiliteRegister = registerForm.elements['civilite-inscription'].value;
            let prenomRegister = document.getElementById('prenom-inscription').value;
            let nomRegister = document.getElementById('nom-inscription').value;
            let emailRegister = document.getElementById('email-inscription').value;
            let telephoneRegister = document.getElementById('telephone-inscription').value;
            let passRegister = document.getElementById('pass-inscription').value;
            let confirmRegister = document.getElementById('confirm-inscription').value;
            let newsletterRegister = document.getElementById('newsletter-inscription').checked === true ? "1" : "0";

            toggleLoader();

            let request = new XMLHttpRequest();
            request.open('GET', `${FunctionsAjax.ajaxurl}?action=ajax_register&civilite-inscription=${civiliteRegister}&email-inscription=${emailRegister}&pass-inscription=${passRegister}&prenom-inscription=${prenomRegister}&nom-inscription=${nomRegister}&telephone-inscription=${telephoneRegister}&newsletter-inscription=${newsletterRegister}&confirm-inscription=${confirmRegister}&security=${FunctionsAjax.security}`, true);
            request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
            request.onload = function() {

                toggleLoader();
                cleanErrorMessages();

                if (200 === this.status) {
                        let rep = JSON.parse(this.response);
                        if(rep.type == "success") {

                            window.dataLayer.push({
                                "event":"account-creation-success"
                            });

                            if(newsletterRegister == "1") {
                                window.dataLayer.push({
                                    "event":"newsletter-success"
                                });
                            }

                            handleSuccess('Inscription réussie', 'Vous allez être redirigé vers la page programme');
                        } else {
                            handleErrors(rep, 'inscription');
                        }
                } else {
                    // add message error
                    console.log('fail | status : ' + this.status + ' | response : ' + this.response);
                }
            };
            request.onerror = function() {
                console.log('connection error');
            };
            request.send();
            console.log(request);
        });
    }

    // Ajax login
    if (loginForm != null && typeof(loginForm) != "undefined") {
        loginForm.addEventListener('submit', function(e) {
            e.preventDefault();
            let emailLogin= encodeURIComponent(document.getElementById('email-connexion').value);
            let passwordLogin= document.getElementById('pass-connexion').value;

            toggleLoader();

            let request = new XMLHttpRequest();
            request.open('GET', `${FunctionsAjax.ajaxurl}?action=ajax_login&email-connexion=${emailLogin}&pass-connexion=${passwordLogin}&security=${FunctionsAjax.security}`, true);
            request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
            request.onload = function() {
            cleanErrorMessages();
            toggleLoader();
             if (200 === this.status) {
                    let rep = JSON.parse(this.response);
                    if(rep.type == "success") {
                        if (FunctionsAjax.is_singular_programme.is_singular) {
                            handleSuccess('Connexion réussie', 'Vous êtes maintenant connecté', '');
                        } else {
                            handleSuccess('Connexion réussie', 'Vous allez être redirigé vers votre espace', FunctionsAjax.is_singular_programme.url);
                        }
                    } else {
                        handleErrors(rep, 'connexion');
                    }
            } else {
                // add message error
                console.log('fail | status : ' + this.status + ' | response : ' + this.response);
            }
            };
            request.onerror = function() {
                console.log('connection error');
            };
            request.send();
        });
    }

    // Ajax Patch
    if (infosForm != null && typeof(infosForm) != "undefined") {
        infosForm.addEventListener('submit', function(e) {
            e.preventDefault();
            let civiliteRegister = infosForm.elements['civilite-informations'].value;
            let prenomRegister = document.getElementById('prenom-informations').value;
            let nomRegister = document.getElementById('nom-informations').value;
            let emailRegister = document.getElementById('email-informations').value;
            let telephoneRegister = document.getElementById('telephone-informations').value;
            let passRegister = document.getElementById('pass-informations').value;
            let confirmRegister = document.getElementById('confirm-informations').value;
            let newsletterRegister = document.getElementById('newsletter-informations').checked === true ? "1" : "0";

            toggleLoader();

            let request = new XMLHttpRequest();
            request.open('GET', `${FunctionsAjax.ajaxurl}?action=ajax_patch&civilite-informations=${civiliteRegister}&email-informations=${emailRegister}&pass-informations=${passRegister}&prenom-informations=${prenomRegister}&nom-informations=${nomRegister}&telephone-informations=${telephoneRegister}&newsletter-informations=${newsletterRegister}&confirm-informations=${confirmRegister}&security=${FunctionsAjax.security}`, true);
            request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
            request.onload = function() {

                toggleLoader();
                cleanErrorMessages();

                if (200 === this.status) {
                        let rep = JSON.parse(this.response);
                        if(rep.type == "success") {
                            handleSuccess('Informations modifiées', 'Vous avez modifié des informations sur votre compte Bâti-Armor ');
                        } else {
                            handleErrors(rep, 'informations');
                        }
                } else {
                    // add message error
                    console.log('fail | status : ' + this.status + ' | response : ' + this.response);
                }
            };
            request.onerror = function() {
                console.log('connection error');
            };
            request.send();
            console.log(request);
        });
    }

    // Ajax Password reset
    if (resetPasswordForm != null && typeof(resetPasswordForm) != "undefined") {
        resetPasswordForm.addEventListener('submit', function(e) {
            e.preventDefault();
            let emailReset = document.getElementById('email-password-oublie').value;

            toggleLoader();

            let request = new XMLHttpRequest();
            request.open('GET', `${FunctionsAjax.ajaxurl}?action=ajax_password_reset&email-password-oublie=${emailReset}&security=${FunctionsAjax.security}`, true);
            request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
            request.onload = function() {

            toggleLoader();
            cleanErrorMessages();

             if (200 === this.status) {
                    let rep = JSON.parse(this.response);
                    if(rep.type == "success") {
                        handleSuccess('Email envoyé', 'Vous allez recevoir un email pour réinitialiser votre mot de passe');
                    } else {
                        handleErrors(rep, 'password-oublie');
                    }
            } else {
                // add message error
                console.log('fail | status : ' + this.status + ' | response : ' + this.response);
            }
            };
            request.onerror = function() {
                console.log('connection error');
            };
            request.send();
            console.log(request);
        });
    }

    // Ajax delete account
    if (deleteBtn != null && typeof(deleteBtn) != "undefined") {
        deleteBtn.addEventListener('click', function(e) {
            e.preventDefault();
            let idUser = deleteBtn.dataset.id;

            let request = new XMLHttpRequest();
            toggleLoader();
            request.open('GET', `${FunctionsAjax.ajaxurl}?action=ajax_delete`, true);
            request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
            toggleLoader();
            request.onload = function() {
                if (200 === this.status) {
                    let rep = JSON.parse(this.response);
                    if(rep.type == "success") {
                        handleSuccess('Compte supprimé', 'Vous allez être redirigé sur la page d\'accueil');
                    } else {
                        handleErrors(rep, 'Une erreur est survenue, veuillez réessayer plus tard.');
                    }
                } else {
                    // add message error
                    console.log('fail | status : ' + this.status + ' | response : ' + this.response);
                }
            };
            request.onerror = function() {
                console.log('connection error');
            };
            request.send();
        });
    }

    // Load datalist
    if (inputSearchDepartement != null && typeof(inputSearchDepartement) != "undefined") {
        var noResultDatalistDepartement = "Aucune département n\'existe avec ce nom";
        var dataListDepartement = inputSearchDepartement.nextElementSibling;

        // Ajax Form Search default Departments (from operations on database)
        loadDataList(`${FunctionsAjax.ajaxurl}?action=ajax_load_datalist&type=departement&current_id_page=${FunctionsAjax.current_id_page}`, dataListDepartement, inputSearchDepartement, noResultDatalistDepartement);
    }
    if (inputSearchVille != null && typeof(inputSearchVille) != "undefined") {
        var noResultDatalistVille = "Aucune ville n\'existe avec ce nom et le département choisi";
        var dataListVille = inputSearchVille.nextElementSibling;

        // Ajax Form Search default City (from operations on database)
        loadDataList(`${FunctionsAjax.ajaxurl}?action=ajax_load_datalist&type=ville&current_id_page=${FunctionsAjax.current_id_page}`, dataListVille, inputSearchVille, noResultDatalistVille);
    }

    // Ajax Form Search keyup Departments (from api gouv)
    if (inputSearchDepartement != null && typeof(inputSearchDepartement) != "undefined") {
        inputSearchDepartement.addEventListener('keyup', delay(function(e) {
            if(dataListDepartement != null) {
                // Recherche seulement si plus de 1 caractère et que la localisation recherchée n'existe pas dans le datalist de base
                var firstOptionValue = dataListDepartement.querySelector('li span:nth-child(2)');
                var optionTextFind = [...dataListDepartement.querySelectorAll('li')]
                .map(option => option.innerText)
                .filter(txt => txt.toLowerCase().includes(inputSearchDepartement.value.toLowerCase()));
                datalistHideShow(dataListDepartement, inputSearchDepartement.value);
                datalistHideShow(dataListVille, inputSearchDepartement.value + ' (');
    
                // keyup load data
                /*if(inputSearchDepartement.value.trim().length > 1 && (optionTextFind.length == 0 || firstOptionValue.innerText == noResultDatalistVille)) {
                    loadDataList(`https://data.enseignementsup-recherche.gouv.fr/api/v2/catalog/datasets/fr-esr-referentiel-geographique/records?where=search(com_nom,"${inputSearchDepartement.value}")&limit=10&order_by=com_nom&offset=0&lang=fr&timezone=Europe%2FParis`, dataListDepartement, inputSearchDepartement, noResultDatalistDepartement);
                }*/
            }
        }, 650));
    }

    // Ajax Form Search keyup City (from api gouv)
    if (inputSearchVille != null && typeof(inputSearchVille) != "undefined") {
        inputSearchVille.addEventListener('keyup', delay(function(e) {
            if(dataListVille != null) {
                // Recherche seulement si plus de 1 caractère et que la localisation recherchée n'existe pas dans le datalist de base
                var firstOptionValue = dataListVille.querySelector('li span:nth-child(2)');
                var optionTextFind = [...dataListVille.querySelectorAll('li')]
                .map(option => option.innerText)
                .filter(txt => txt.toLowerCase().includes(inputSearchVille.value.toLowerCase()));
                datalistHideShow(dataListVille, inputSearchVille.value);

                // keyup load data
                if(inputSearchVille.value.trim().length > 1 && (optionTextFind.length == 0 || firstOptionValue.innerText == noResultDatalistVille)) {
                    var com_dep_where = "";
                    if (inputSearchDepartement != null && typeof(inputSearchDepartement) != "undefined") {
                        var dpt_txt = inputSearchDepartement.value.trim();
                        if(dpt_txt.length > 1) {
                            com_dep_where = ` AND search(dep_nom, "${sanitizeString(dpt_txt)}")`;
                        }
                    }
                    loadDataList(`https://data.enseignementsup-recherche.gouv.fr/api/v2/catalog/datasets/fr-esr-referentiel-geographique/records?where=search(com_nom,"${inputSearchVille.value}")${com_dep_where}&limit=10&order_by=com_nom&offset=0&lang=fr&timezone=Europe%2FParis`, dataListVille, inputSearchVille, noResultDatalistVille);
                }
            }
        }, 650));
    }

    function datalistHideShow(datalist, value, type = "like") {
        datalist.querySelectorAll('li').forEach(function (element, id) {
            if(type == "like") {
                if(!element.innerText.toLowerCase().includes(value.toLowerCase())) {
                    element.style.display = "none";
                } else {
                    element.style.display = "block";
                }
            } /*else if(type == "=") {
                if(element.innerText.toLowerCase() != value.toLowerCase()) {
                    element.style.display = "none";
                } else {
                    element.style.display = "block";
                }
            }*/
        });
    }

    // Fetch and load dataList with data
    function loadDataList(url, dataList, element, noResultDatalist) {
        if(dataList != null) {
            let request = new XMLHttpRequest();
            request.open('GET', url, true);
            request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
            request.onload = function() {
                if (200 === this.status) {
                    let rep = JSON.parse(this.response);
                    if(rep.total_count > 0) {
                        var options = '';
                        for (let i = 0; i <= rep.total_count; i++) {
                            if(rep.records[i] !== undefined) {
                                //let nom = rep.records[i].record.fields.com_nom + " (" + rep.records[i].record.fields.dep_code + ")";
                                var txtGeolocalisation = "";
                                if(rep.records[i].record.fields.hasOwnProperty('geolocalisation') && rep.records[i].record.fields.geolocalisation != null) {
                                    txtGeolocalisation = 'data-lng="' + rep.records[i].record.fields.geolocalisation.lon + '" data-lat="' + rep.records[i].record.fields.geolocalisation.lat + '"';
                                }

                                
    
                                if(url.includes('type=departement')) {
                                    options += '<li tabIndex="-1" class="datalistoption" ' + txtGeolocalisation + '><span class="datalistdepartement">' + rep.records[i].record.fields.dep_nom + '</span><span class="datalistcp">' + rep.records[i].record.fields.dep_code + '</span></li>';
                                } else {
                                    options += '<li tabIndex="-1" class="datalistoption" ' + txtGeolocalisation + '><span class="datalistville">' + rep.records[i].record.fields.com_nom + '</span><span class="datalistdepartement">' + rep.records[i].record.fields.dep_nom_num + '</span></li>';
                                }
                            }
                        }
                        dataList.innerHTML = options;
                    } else {
                        dataList.innerHTML = '<li tabIndex="-1" class="datalistoption"><span class="datalistville">' + element.value + '</span><span class="datalistdepartement">' + noResultDatalist + '</span></li>';
                    }
                    datalistHideShow(dataList, element.value);

                    // after load ville, last loadataList 
                    // + on first load > Simulate keyup for trigger listener to show/hide element in dataList
                    if (url.includes('type=ville') && inputSearchDepartement != null && typeof(inputSearchDepartement) != "undefined") {
                        inputSearchDepartement.dispatchEvent(new KeyboardEvent('keyup', {'key': 'Space'}));
                    }
                    
                } else {
                    // add message error
                    console.log('fail | status : ' + this.status + ' | response : ' + this.response);
                }
            };
            request.onerror = function() {
                console.log('connection error');
            };
            request.send();
        }
    }

})();

// Utils
function delay(fn, ms) {
    let timer = 0
    return function(...args) {
        clearTimeout(timer)
        timer = setTimeout(fn.bind(this, ...args), ms || 0)
    }
}

function toggleLoader () {
    let loaderBg = document.querySelectorAll('.loader-background');
    let bannerContent = document.querySelectorAll('.banner-content');
    for (let el of loaderBg) {
        el.classList.toggle('loader-active');
    }
    for (let el of bannerContent) {
        el.classList.toggle('banner-opacity');
    }
}

function cleanErrorMessages () {
    let prevConnexionSpan = document.querySelectorAll('.form-errors');
    if (prevConnexionSpan.length > 0) {
        for(let prev of prevConnexionSpan) {
            prev.remove();
        }
    }
}

function handleSuccess(titleText, messageText, url = '') {
    let toggleInput = document.getElementById('success-panel');
    let title = document.getElementById('confirmation-title');
    let message = document.getElementById('confirmation-message');
    title.innerText = titleText;
    message.innerText = messageText;
    toggleInput.checked = true;
    window.setTimeout(() => {
        toggleInput.checked = false;
        if (url !== '') {
            document.location.href = url;
        } else {
            document.location.reload(true);
        }
    }, 1700);
}

function handleErrors(rep, formName) {
    rep.type.forEach((type, index) => {
        let spanMessage = document.createElement('span');
        spanMessage.setAttribute('class', 'form-errors');
        let parent = '';
        if (rep['type'][index] == 'civilite') {
            parent = document.getElementsByClassName('civilite')[0];
        } else {
            parent = document.getElementById(rep['type'][index] + '-' + formName);
        }
        spanMessage.innerHTML = rep['message'][index];
        parent.after(spanMessage);
    });
}

function sanitizeString(str) {
    var r = str.trim().toLowerCase();
    r = r.replace(new RegExp(/\s/g), "-");
    r = r.replace(new RegExp(/[àáâãäå]/g), "a");
    r = r.replace(new RegExp(/æ/g), "ae");
    r = r.replace(new RegExp(/ç/g), "c");
    r = r.replace(new RegExp(/[èéêë]/g), "e");
    r = r.replace(new RegExp(/[ìíîï]/g), "i");
    r = r.replace(new RegExp(/ñ/g), "n");
    r = r.replace(new RegExp(/[òóôõö]/g), "o");
    r = r.replace(new RegExp(/œ/g), "oe");
    r = r.replace(new RegExp(/[ùúûü]/g), "u");
    r = r.replace(new RegExp(/[ýÿ]/g), "y");
    r = r.replace(new RegExp(/\W/g), "-");
    return r;
}
